export default [
    {
        header: 'Brand',
    },
    {
        title: 'Brands',
        route: 'brands',
        icon: 'AwardIcon',
    },
    {
        title: 'Members',
        route: 'members',
        icon: 'UsersIcon',
    },
    {
        title: 'AI Comment',
        route: 'ai-auto-reply-comment',
        icon: 'CpuIcon',
    }
]