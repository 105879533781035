export default [
    {
        header: 'Support & Help',
    },
    {
        title: 'Docs',
        route: 'docs',
        icon: 'FileTextIcon',
    },
    {
        title: 'Settings',
        route: 'account',
        icon: 'SettingsIcon',
    },
    {
        title: 'Support',
        route: 'support',
        icon: 'PhoneIcon',
    }
]