export default [
    {
        header: 'Channel',
    },
    {
        title: 'Social Channels',
        route: 'channels',
        icon: 'Share2Icon'
    },
    {
        title: 'Posts',
        route: 'posts',
        icon: 'SendIcon'
    },
    {
        title: 'Calendar',
        route: 'calendar',
        icon: 'CalendarIcon',
    },
    {
        title: 'Automation',
        route: 'automation',
        icon: 'RepeatIcon',
        tag: 'soon',
        tagVariant: 'light-warning',
    }
]