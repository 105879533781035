export default [
    {
        header: 'Tools',
    },
    {
        title: 'Media Library',
        route: 'media-library',
        icon: 'ImageIcon'
    },
    {
        title: 'Link In Bio',
        route: 'pages',
        icon: 'ListIcon'
    },
    {
        title: 'Link Shortener',
        route: 'link-shortener',
        icon: 'LinkIcon'
    }
]