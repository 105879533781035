<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item :to="{name: 'subscription'}" v-if="$store.state.auth.user.account.plan.name == 'Free' || $store.state.auth.user.account.plan.name == ''">
          <b-button size="sm" variant="warning">
            <feather-icon
              icon="ArrowUpIcon"
              class="mr-50"
            />
            <span class="align-middle">Upgrade Plan</span>
          </b-button>
      </b-nav-item>
      <locale />
      <brands />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{$store.state.auth.user.current_brand_name}}
            </p>
            <span class="user-status">{{$store.state.auth.user.current_brand_permission}}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="$store.state.auth.user.current_brand_image"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item :to="{name: 'account'}" link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Account</span>
        </b-dropdown-item>
        <b-dropdown-item :to="{name: 'subscription'}" link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CreditCardIcon"
            class="mr-50"
          />
          <span>Subscription</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item @click="logout()" link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BButton, BNavItem,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import Brands from '@core/layouts/components/app-navbar/components/Brands.vue'
import axios from 'axios'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar, BButton, BNavItem,

    // Navbar Components
    DarkToggler,
    Locale,
    Brands
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    async logout() {
        await axios
          .post('/token/logout/')
          .then(response => {
            console.log('Logged out')
          })
          .catch(error => {
              console.log(JSON.stringify(error))
          })
        
        axios.defaults.headers.common['Authorization'] = ''
        localStorage.removeItem('token')
        localStorage.removeItem('email')
        localStorage.removeItem('userid')
        localStorage.removeItem('fullname')
        localStorage.removeItem('account_id')
        this.$store.commit('auth/removeToken')
        this.$router.push('/login')
    }
  }
}
</script>
