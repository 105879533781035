<template>
    <b-nav-item-dropdown
        class="dropdown-notification mr-25"
        menu-class="dropdown-menu-media"
        right
    >
        <template #button-content>
            <feather-icon
                class="text-body"
                icon="RefreshCcwIcon"
                size="21"
            />
        </template>

        <!-- Header -->
        <li class="dropdown-menu-header">
            <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">
                    Switch Brands
                </h4>
                <b-badge
                    pill
                    variant="light-primary"
                >
                    {{ brands.length }} Brands
                </b-badge>
            </div>
        </li>

        <!-- brands -->
        <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="scrollable-container media-list scroll-area"
            tagname="li"
        >
            <!-- Account brand -->
            <b-link
                v-for="brand in brands"
                :key="brand.id"
                @click="setBrand(brand)"
            >
                <b-media>
                    <template #aside>
                        <b-avatar
                            size="32"
                            :src="brand.image"
                        />
                    </template>
                    <p class="media-heading">
                        <span class="font-weight-bolder">
                            {{ brand.name }}
                        </span>
                    </p>
                    <small class="notification-text">{{ brand.permission }}</small>
                </b-media>
            </b-link>
        </vue-perfect-scrollbar>

        <!-- Cart Footer -->
        <li class="dropdown-menu-footer"><b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="gotToBrands"
        >Brand Settings</b-button>
        </li>
    </b-nav-item-dropdown>
</template>

<script>
import {
    BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'

export default {
    components: {
        BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, VuePerfectScrollbar, BButton, BFormCheckbox, ToastificationContent
    },
    directives: {
        Ripple,
    },
    mounted(){
        this.getBrands()
    },
    data(){
        return {
            brands: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            }
        }
    },
    methods: {
        gotToBrands(){
            this.$router.push('/brands')
            // disable dropdown
            document.body.click()
        },
        setBrand(brand){
            this.$store.commit('auth/setBrand', brand)
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Brand set Succesfully',
                    icon: 'CheckIcon',
                    text: 'I do not think that word means what you think it means.',
                    variant: 'success',
                },
            })
            // disable dropdown
            document.body.click()
            this.$router.go()
        },
        getBrands(){
            axios.get("/brands")
            .then(response => {
                console.log(response);
                this.brands = response.data
                setTimeout(() => { this.showLoader = false }, 500);
            })
            .catch(error => {
                console.log(error);
            })
        },
    }
}
</script>

<style>

</style>
  